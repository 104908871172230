import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { client, historique } from '../types';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography, Button, Paper } from '@mui/material';
import {  intervalTime } from '../utils';

function HistoriqueClient() {
  let navigate = useNavigate()

  const queryParameters = new URLSearchParams(window.location.search)
  const clientID = queryParameters.get("id")

  const [client, setClient] = useState<client>();

  const [histo, setHisto] = useState<historique[]>()

  useEffect(() => {
    getHisto()
    getClient()
    const interval = setInterval(() => {
      getHisto()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getHisto = async () => {
    try {
      if (!clientID) return navigate("/historique")
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/historique/client', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({clientID: parseInt(clientID), amount: 50})
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: historique[] = await response.json();

      setHisto(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getClient = async () => {
    if (!clientID) return navigate("/client")
    if (Number.isNaN(parseInt(clientID))) return navigate("/client")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/get', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ clientID: parseInt(clientID) })
      })

      const data: client = await response.json();
      return setClient(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const formatType = (type: string, amount: number | null) => {
    if (amount === null) amount = 0
    if (type === "blanchi") return `Blanchi pour ${-amount}$`
    if (type === "rachat") return `Achete pour ${-amount}$`
    if (type === "vente") return `Vend pour ${amount}$`

  }

  const formatWho = (histo: historique) => {
    if (histo.client) {
      if (histo.client.number !== "555-") return `à ${histo.client.name} (${histo.client.number})`
      return `à ${histo.client.name}`
    }
    return histo.stockage?.name
  }

  const formatQuantity = (type: string, quantity: number) => {
    // if (type === "stockfix") return quantity
    if (type === "stockadd") return quantity < 0 ? quantity : "+" + quantity
    return quantity
  }

  const colorQuantity = (type: string, quantity: number) => {
    if (type === "stockfix") return '#db8216'
    else if (type === "stockadd") {
      if (quantity > 0) return '#1fb6db'
      return '#b780ff'
    }

    return "text.primary"
  }

  const getLink = (histo: historique) => {
    if (histo.client) return `/historique?id=${histo.clientID}`
    if (histo.StockageID) return `/stockage?id=${histo.StockageID}`
    return ``
  }

  return (
    <>
        <Box sx={{ p: 2 }}>
          <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Historique pour {client?.name} {client?.number ? `(${client.number})`: ""}</Typography>
        </Box>
        <Paper elevation={16} sx={{ p: 2}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Qui</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Type</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Pour</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Combien</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Quoi</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Quand</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {histo?.map(histo => {
                return (
                  <TableRow key={Math.random()}>
                    <TableCell sx={{ textAlign: "center" }}>{histo.user.name}</TableCell>
                    <TableCell sx={{ textAlign: "center", color: histo.type.startsWith("stock") ? "primary.main" : "success.main" }}> {formatType(histo.type, histo.price)} </TableCell>
                    <TableCell sx={{ textAlign: "center", color: histo.type.startsWith("stock") ? "primary.main" : "success.main" }} >
                      <Button variant='outlined' component={Link} to={getLink(histo)} color={histo.type.startsWith("stock") ? "primary" : "success"}>{formatWho(histo)}
                      </Button>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", color: colorQuantity(histo.type, histo.quantity) }}> {formatQuantity(histo.type, histo.quantity)} </TableCell>
                    <TableCell sx={{ textAlign: "center", color: histo.item.color ? histo.item.color : "text.primary" }}> {histo.item.name} </TableCell>
                    <TableCell sx={{ textAlign: "center" }}> {new Date(histo.createdAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })} </TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
 </>
  );
}

export default HistoriqueClient;
