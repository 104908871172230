import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { item, User } from '../types';
import Header from '../components/header';
import { intervalTime, role } from '../utils';
import { Box, Table, TableRow, TableCell, TableBody, Button, TextField, Checkbox, FormControlLabel, Paper, Typography, Snackbar, Alert } from '@mui/material';
import { MuiColorInput, MuiColorInputColors, MuiColorInputValue } from 'mui-color-input'

function GestionItem({ user }: { user: User | undefined }) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')


  const [open, setOpen] = useState(false)
  const [notifText, setNotifText] = useState("")
  const handleClose = () => setOpen(false)

  const [itemList, setItemList] = useState<item[]>()

  const [value, setValue] = React.useState<MuiColorInputValue>('#ffffff')

  const handleChange = (newValue: string, colors: MuiColorInputColors) => {
    setValue(newValue)
  }

  const [newItem, setNewItem] = useState<item>({
    alwaysShow: false,
    color: "",
    name: "",
    poids: 0,
    id: 0
  })

  useEffect(() => {
    getItemList()
    const interval = setInterval(() => {
      getItemList()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();
      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const changeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setItemList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.name = e.target.value
        }
        return item
      })
      return list
    })
  }

  const changePoids = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setItemList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.poids = parseInt(e.target.value)
        }
        return item
      })
      return list
    })
  }

  const changeColor = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setItemList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.color = e.target.value
        }
        return item
      })
      return list
    })
  }

  const changeShow = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setItemList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.alwaysShow = e.target.checked
        }
        return item
      })
      return list
    })
  }

  const changeItem = async (id: number) => {
    let item

    if (!itemList) return
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].id === id) {
        item = itemList[i]
        break
      }
    }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/item/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ item })
    })


    setNotifText("L'item a bien été modifié")
    getItemList()
    setOpen(true)
  }

  const addItem = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/item/create', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ newItem })
    })

    getItemList()
    setNewItem({
      alwaysShow: false,
      color: "",
      name: "",
      poids: 0,
      id: 0
    })

    setNotifText("L'item a bien été ajouté")
    setOpen(true)
  }

  return (
    <>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste Item
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableBody>
            {itemList?.map(item => {
              return (
                <TableRow key={Math.random()} >
                  <TableCell >
                    <TextField size='small' id='mouvement' label="Nom" defaultValue={item.name} variant='outlined' onBlur={(e) => changeName(e, item.id)} InputProps={{ sx: { color: item.color } }} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >
                    <TextField size='small' id='mouvement' label="Poids" defaultValue={item.poids} variant='outlined' onBlur={(e) => changePoids(e, item.id)} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >
                    <TextField size='small' id='mouvement' label="Couleur" defaultValue={item.color} variant='outlined' onBlur={(e) => changeColor(e, item.id)} InputProps={{ sx: { color: item.color } }} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={item.alwaysShow} onChange={(e) => { changeShow(e, item.id) }}
                        />
                      }
                      label="Toujours Montrer"
                      labelPlacement='end'
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >

                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={() => changeItem(item.id)}>Modifier</Button>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow key={Math.random()} >
              <TableCell >
                <TextField size='small' id='mouvement' label="Nom" defaultValue={newItem.name} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, name: e.target.value }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >
                <TextField size='small' id='mouvement' label="Poids" defaultValue={newItem.poids} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, poids: parseInt(e.target.value) }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >
                <TextField size='small' id='mouvement' label="Couleur" defaultValue={newItem.color} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, color: e.target.value }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newItem.alwaysShow} onChange={(e) => setNewItem(prev => ({ ...prev, alwaysShow: e.target.checked }))}
                    />
                  }
                  label="Toujours Montrer"
                  labelPlacement='end'
                />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >

                <Button color='success' variant='contained' sx={{ textAlign: "center" }} onClick={() => addItem()}>Ajouter</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <br />

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>

      <MuiColorInput value={value} onChange={handleChange} format={"hex"} isAlphaHidden />

    </>
  );
}

export default GestionItem;
