import { Outlet } from "react-router-dom"
import Header from "./header"
import { User } from "../types"
import '../pages/css/home.css';
import { useEffect } from "react";

const Layout = ({user}: {user: User | undefined}) => {

    useEffect(() => {
        document.body.classList.remove('discord')
    })

    return (
        <div className="App">
        <Header user={user} ></Header>
        <header className="App-body">
            <Outlet />
        </header>
        </div>
    )
}

export default Layout