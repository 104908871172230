import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { client, CN, price, transac, User } from '../types';
import Header from '../components/header';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, MenuItem, Checkbox, FormControlLabel, Typography, Paper } from '@mui/material';
import { formatCurrency, formatWeight, getCurrencyColor, intervalTime } from '../utils';
import HistoriqueClient from '../components/historiqueClient';

function Transaction() {
  let navigate = useNavigate()

  const queryParameters = new URLSearchParams(window.location.search)
  const clientID = queryParameters.get("id")

  const [client, setClient] = useState<client>();

  const [basePriceList, setBasePriceList] = useState<price[]>();

  const [cnList, setCnList] = useState<CN[]>([])

  const [newItems, setNewItems] = useState<number>(1)

  const [transacList, setTransac] = useState<transac[]>([])

  const [selectedCN, setSelectedCN] = useState<{ selectedCN: number, mouvement: boolean }>({ selectedCN: 1, mouvement: false })

  const getClient = async () => {
    if (!clientID) return navigate("/client")
    if (Number.isNaN(parseInt(clientID))) return navigate("/client")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/get', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ clientID: parseInt(clientID) })
      })

      const data: client = await response.json();
      return setClient(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getPrice = async () => {
    if (!clientID) return navigate("/client")
    if (Number.isNaN(parseInt(clientID))) return navigate("/client")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/getPrice', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ clientID: parseInt(clientID) })
      })

      const data: price[] = await response.json();
      // return setClient(data)
      setBasePriceList(data)

      let transacTemp: transac[] = []

      for (let i = 0; i < data.length; i++) {
        if (data[i].alwaysShow) {
          transacTemp.push(
            {
              id: Math.random() * Date.now(),
              item: data[i].item,
              pourcentage: data[i].pourcentage,
              price: data[i].price,
              type: data[i].type,
              specialPrice: data[i].specialPrice,

              poidsTotal: 0,
              totalPrice: 0,
              quantity: 0,
            }
          )
        }
      }

      setTransac(transacTemp)


    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  useEffect(() => {
    getClient()
    getPrice()
    getCNList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const getCNList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: CN[] = await response.json();

      setCnList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getBasePrice = (price: transac) => {
    if (price.type === "blanchi") {
      return price.pourcentage
    }
    else return price.price

  }

  const calculPoid = (price: transac) => {
    return price.quantity * price.item.poids

  }

  const calculPrice = (price: transac) => {
    let prix = 0
    if (price.type === "blanchi") {
      if (price.pourcentage) prix = price.quantity * (1 - (price.pourcentage / 100))
    } else if (price.type === "vente") {
      if (price.price) prix = -(price.quantity * price.price)
    } else if (price.type === "rachat") {
      if (price.price) prix = (price.quantity * price.price)
    }
    return prix

  }

  const changeQuantity = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setTransac(tl => {
      const list = tl.map((transac) => {
        if (transac.id === id) {
          transac.quantity = parseInt(e.target.value)
        }
        return transac
      })
      return list
    })
  }

  const changePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setTransac(tl => {
      const list = tl.map((transac) => {
        if (transac.id === id) {
          if (transac.type === "blanchi") {
            transac.pourcentage = parseInt(e.target.value)
          } else {
            transac.price = parseInt(e.target.value)
          }
        }
        return transac
      })
      return list
    })
  }

  const addItem = () => {
    let transacItem: price
    if (!basePriceList) return

    for (let i = 0; i < basePriceList.length; i++) {
      if (newItems === basePriceList[i].id) {
        transacItem = basePriceList[i]
        break
      }
    }

    setTransac(prev => [
      ...prev,
      {
        id: Math.random() * Date.now(),
        item: transacItem.item,
        pourcentage: transacItem.pourcentage,
        price: transacItem.price,
        type: transacItem.type,
        specialPrice: transacItem.specialPrice,

        poidsTotal: 0,
        totalPrice: 0,
        quantity: 0,
      }
    ]
    )

  }

  const calculTotalPrice = () => {
    let totalPrice = 0
    for (let i = 0; i < transacList.length; i++) {
      totalPrice += calculPrice(transacList[i])
    }
    return totalPrice
  }

  const calculTotalWeight = () => {
    let totalWeight = 0
    for (let i = 0; i < transacList.length; i++) {
      totalWeight += calculPoid(transacList[i])
    }
    return totalWeight
  }

  const handlePayement = async () => {
    // transacList
    await fetch(process.env.REACT_APP_BACKEND_URL + '/client/addTransac', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ transacList, selectedCN, client })
    })

    return navigate("/historique")
  }

  return (

    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Gestion Client : {client?.name} {client?.type === "PM" ? `(${client.number})` : ""}
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell />
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transacList?.map((transac, index) => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell sx={{ textAlign: "center", color: transac.item.color }}>{transac.item.name}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <TextField sx={{ margin: "1rem", width: "6rem" }} label="Quantité" variant='outlined' defaultValue={transac.quantity} onBlur={(e) => changeQuantity(e, transac.id)} />
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }}>
                    <TextField sx={{ margin: "1rem", width: "6rem" }} label={transac.type === "blanchi" ? "%" : "Prix"} variant='outlined' defaultValue={getBasePrice(transac)} onBlur={(e) => changePrice(e, transac.id)} />
                  </TableCell>
                  <TableCell />
                  <TableCell sx={{ textAlign: "center" }}>{formatCurrency(calculPrice(transac))}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{formatWeight(calculPoid(transac))}</TableCell>

                </TableRow>
              )
            })}

            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>
                <TextField id='addItem' select label="Item" variant='outlined' value={newItems}
                  onChange={(e) => { setNewItems(parseInt(e.target.value)) }}
                  InputProps={{ sx: { color: basePriceList?.filter(item => item.id === newItems).map(o => o.item.color) } }}
                  SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}>
                  {basePriceList?.map((option) => (
                    <MenuItem key={option.item.name} value={option.id} sx={{ color: option.item.color }}>
                      {option.item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <Button onClick={addItem}> Ajouter L'item</Button>
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell />
              <TableCell sx={{ textAlign: "center" }}></TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableBody>
          <TableCell sx={{ textAlign: "center" }}>
            <TextField id='cn' select label="Compte" variant='outlined' value={selectedCN.selectedCN} onChange={(e) => { setSelectedCN(prev => ({ ...prev, selectedCN: parseInt(e.target.value) })) }}>
              {cnList.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
          <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={(e) => { setSelectedCN(prev => ({ ...prev, mouvement: e.target.checked })) }}
                />
              }
              label="Mouvement CN"
              labelPlacement='bottom'
            />
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            <Button variant='outlined' color='success' onClick={() => handlePayement()}>Payer</Button>
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>Total :</TableCell>
          <TableCell sx={{ textAlign: "center", color: getCurrencyColor(-calculTotalPrice()) }}>
            {formatCurrency(calculTotalPrice())}
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            {formatWeight(calculTotalWeight())}
          </TableCell>
        </Table>
      </Paper>
      <HistoriqueClient />
    </>
  );
}

export default Transaction;
