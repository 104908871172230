import React, { useEffect, useState } from 'react';
import './css/home.css';
import Header from '../components/header';
import { item, itemTotal, storage, stock } from '../types';
import { Box, Button, Typography, MenuItem, TextField, Paper, Popover, Tooltip } from '@mui/material';
import { formatAmount, formatWeight, intervalTime } from '../utils';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { EditOutlined, ExposureOutlined } from '@mui/icons-material';

function Stockage() {

  const queryParameters = new URLSearchParams(window.location.search)
  const stockageID = queryParameters.get("id")

  const [stock, setStock] = useState<storage[]>()
  const [totalStock, setTotalStock] = useState<GridRowsProp>()
  const [itemList, setItemList] = useState<item[]>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [mouvementStock, setMouvementStock] = useState<{ itemID: number, quantity: number | undefined, stockageID: number, type: string | undefined }>({
    itemID: 1,
    quantity: undefined,
    stockageID: 1,
    type: ""
  })

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , id: number, stockageID: number, type: string) => {
    setMouvementStock(prev => ({ ...prev, itemID: id, stockageID: stockageID, type: type }))
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null);

  const isPopoverOpen = Boolean(anchorEl)

  // console.log(process.env.NODE_ENV)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Quoi ?',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<itemTotal>) => (
        <Box sx={{ color: params.row.color ? params.row.color : "text.primary" }}>{params.row.name}</Box>
      )
    },
    {
      field: 'quantity',
      headerName: 'Quantité',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<itemTotal>) => (
        <Box sx={{ color: params.row.color ? params.row.color : "text.primary" }}>{formatAmount(params.row.quantity)}</Box>
      )
    },
    {
      field: 'poids',
      headerName: 'Poids',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      valueFormatter: (value) => formatWeight(value as number)
    }
  ]

  const columnsStock: GridColDef[] = [
    {
      field: 'item',
      headerName: 'Quoi ?',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<stock>) => (
        <Box sx={{ color: params.row.item.color ? params.row.item.color : "text.primary" }}>{params.row.item.name}</Box>
      )
    },
    {
      field: 'quantity',
      headerName: 'Quantité',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<stock>) => (
        <Box sx={{ color: params.row.item.color ? params.row.item.color : "text.primary" }}>{formatAmount(params.row.quantity)}</Box>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      getActions: (params: GridRowParams<stock>) => [
        <Tooltip title="Corriger" disableInteractive><GridActionsCellItem icon={<EditOutlined />} label='Corriger' onClick={(e) => handleOpen(e, params.row.itemID, params.row.storageID, "fix")} /></Tooltip>,
        <Tooltip title="Ajouter/Retirer" disableInteractive><GridActionsCellItem icon={<ExposureOutlined />} label='Deposer/Retirer' onClick={(e) => handleOpen(e, params.row.itemID, params.row.storageID, "add")} /></Tooltip>,
      ]
    }
  ]

  useEffect(() => {
    getItemList()
    getStock()
    getTotalStock().then(() => {
      if (stockageID) moveToStock(stockageID)
    })
    const interval = setInterval(() => {
      getStock()
      getTotalStock()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getStock = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/get', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: storage[] = await response.json();

      setStock(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }
  const getTotalStock = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/getTotal', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: GridRowsProp = await response.json();
      setTotalStock(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }
  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();
      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const handleMouvement = async () => {
    if (mouvementStock.quantity === undefined) return
    await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/addItem', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(mouvementStock)
    })

    console.log(mouvementStock)

    handleClose()
    getStock()
    getTotalStock()

    // setOpen(true)
    // setTimeout(() => {
    //   setOpen(false)
    // }, 2000)
  }


  const moveToStock = (id: string) => {
    console.log(id)
    setTimeout(() => {
      const correctStock = document.getElementById(id);
      if (correctStock) {
        correctStock.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }

  return (
    <>

      <Box sx={{}}>
        {stock?.map(stock => {
          return (
            <Button variant='outlined' sx={{ marginLeft: "0.5rem" }} onClick={() => moveToStock(stock.id.toString())}>{stock.owner ? stock.owner : stock.name}</Button>
          )
        })}
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography variant="h3" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          STOCKAGE GLOBAL
        </Typography>
      </Box>

      <Paper elevation={16} sx={{ p: 2, width: 900 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            // initialState={{
            //   density: 'compact',
            // }}
            pageSizeOptions={[15]}
            rows={totalStock}
            columns={columns}
            disableColumnMenu={true}
            slots={{ toolbar: GridToolbar }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }


              }
            }}
          />

        </Box>
      </Paper>

      <Box sx={{ p: 2 }}>
        <Typography variant="h3" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          STOCKAGE

        </Typography>
      </Box>

      {
        stock?.map(stock => {
          return (
            <>
              <Box sx={{ p: 2 }}>
                <Typography variant='h5' color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

                  {`${stock.name}  ${stock.owner || ""}${stock.cohabitant ? "," : ""} ${stock.cohabitant || ""}`}
                </Typography>
              </Box>
              <Paper id={stock.id.toString()} elevation={16} sx={{ p: 2, width: 700 }}>
                <DataGrid
                  rows={stock.stockage as GridRowsProp}
                  columns={columnsStock}
                  disableColumnMenu={true}
                  slots={{ toolbar: GridToolbar }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true }


                    }
                  }}
                />
                <Button onClick={(e) => handleOpen(e, 1, stock.id, "fix")}>Ajouter</Button>
              </Paper>
            </>
          )
        })
      }

      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            {mouvementStock.type === "fix" ? "Modifier le stock" : "Ajouter/Retirer au stock"}
          </Typography>
          <TextField sx={{ margin: "1rem" }} id='stockage' select label="Stockage" variant='outlined' value={mouvementStock.stockageID} onChange={(e) => { setMouvementStock(prev => ({ ...prev, stockageID: parseInt(e.target.value) })) }}>
            {stock?.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField sx={{ margin: "1rem" }} id='item' select label="Quoi ?" variant='outlined' value={mouvementStock.itemID}
            onChange={(e) => { setMouvementStock(prev => ({ ...prev, itemID: parseInt(e.target.value) })) }}
            InputProps={{ sx: { color: itemList?.filter(item => item.id === mouvementStock.itemID).map(o => o.color) } }}
            SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }} >
            {itemList?.map((option) => (
              <MenuItem key={option.name} value={option.id} sx={{ color: option.color }}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField sx={{ margin: "1rem" }} id='mouvement' label="Quantité" variant='outlined' error={Number.isNaN(mouvementStock.quantity)} onChange={(e) => { setMouvementStock(prev => ({ ...prev, quantity: parseInt(e.target.value) })) }} />

          <Button onClick={handleMouvement}>Valider</Button>
        </Paper>
      </Popover>

    </>
  );
}

export default Stockage;
