import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { CN, remboursement, User } from '../types';
import Header from '../components/header';
import { formatAmount, formatCurrency, intervalTime } from '../utils';
import { Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, MenuItem, TextField, Button, Alert, Snackbar } from '@mui/material';

function Remboursement({user}: {user: User | undefined}) {
  let navigate = useNavigate()

  const [listReboursement, setListReboursement] = useState<remboursement[]>()
  const [cnList, setCnList] = useState<CN[]>([])
  const [listUser, setListUser] = useState<User[]>([])
  const [notifText, setNotifText] = useState("")

  const [selectedCN, setSelectedCN] = useState(1)

  const [newRemboursement, setNewRemboursement] = useState<{ userID: number, amount: number, reason: string, amountValue: string }>({
    userID: 1,
    amount: 0,
    amountValue: "",
    reason: ""
  })

  const [listUserRemboursement, setListUserRemboursement] = useState<{ user: User, amount: number, checked: boolean }[]>([])

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    getRemboursement()
    getUserList()
    getCNList()
    const interval = setInterval(() => {
      getRemboursement()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getUserList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: User[] = await response.json();

      setListUser(data)
      setNewRemboursement(prev => ({...prev,
        userID: user?.id || listUser[0].id,
      }))

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getRemboursement = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/remboursement/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: remboursement[] = await response.json();

      let tempArray: { user: User, amount: number, checked: boolean }[] = [
        {
          user: data[0].user,
          amount: data[0].amount,
          checked: false
        }
      ]

      for (let i = 1; i < data.length; i++) {
        let exist = false
        for (let y = 0; y < tempArray.length; y++) {
          if (tempArray[y].user.id === data[i].UserID) {
            tempArray[y].amount += data[i].amount
            exist = true
            continue
          }
        }
        if (exist) continue
        tempArray.push({
          user: data[i].user,
          amount: data[i].amount,
          checked: false
        })
      }

      console.log(tempArray)

      setListUserRemboursement(tempArray)
      setListReboursement(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }
  const getCNList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: CN[] = await response.json();

      setCnList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const changeShow = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setListUserRemboursement(tl => {
      const list = tl?.map((item) => {
        if (item.user.id === id) {
          item.checked = e.target.checked
        }
        return item
      })
      return list
    })
  }

  const handlePayementClick = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/remboursement/validate', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ listUserRemboursement, selectedCN })
    })

    getRemboursement()
    setListUserRemboursement([])
    setListReboursement([])
    setNotifText("Les remboursement ont été effectuer")
    setOpen(true)
  }

  const handleAddRemboursement = async () => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/remboursement/add', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newRemboursement)
    })

    setNewRemboursement({
      userID: user?.id || listUser[0].id,
      amount: 0,
      amountValue: "",
      reason: ""
    })
    getRemboursement()
    setNotifText("La demande de remboursement a été ajouté")
    setOpen(true)
  }


  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Remboursement a faire :
        </Typography>
      </Box>

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
          </TableHead>
          <TableBody>
            {
              listUserRemboursement?.map(remboursement => {
                return (
                  <TableRow key={Math.random()} >
                    <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                      <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={remboursement.checked} onChange={(e) => changeShow(e, remboursement.user.id)} />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{remboursement.user.name}</TableCell>
                    <TableCell sx={{ textAlign: "center" }} > {formatCurrency(remboursement.amount)}</TableCell>
                  </TableRow>

                )
              })
            }
            <TableRow>
              <TableCell>
                <TextField id='cn' select label="Compte" variant='outlined' value={selectedCN} onChange={(e) => { setSelectedCN(parseInt(e.target.value)) }}>
                  {cnList.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell></TableCell>
              <TableCell> <Button variant='outlined' color='success' onClick={handlePayementClick}> Valider</Button></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Box sx={{ p: 2 }}>
        <Typography variant="h5" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          Ajouté un remboursement

        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField id='mouvement' label="Somme" variant='outlined' value={newRemboursement.amountValue} error={Number.isNaN(newRemboursement.amount) || newRemboursement.amount < 0} onChange={(e) => {
            setNewRemboursement(prev => ({ ...prev, amount: parseInt(e.target.value), amountValue: e.target.value }))
          }} />
          <TextField id='commentaire' label="Raison" variant='outlined' value={newRemboursement.reason} onChange={(e) => { setNewRemboursement(prev => ({ ...prev, reason: e.target.value })) }} />
          <TextField id='cn' select label="Qui ?" variant='outlined' value={newRemboursement.userID} onChange={(e) => { setNewRemboursement(prev => ({ ...prev, userID: parseInt(e.target.value) })) }}>
            {listUser.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Button variant='outlined' color='success' onClick={handleAddRemboursement}>
          Ajouter
        </Button>
      </Paper>

      <Box sx={{ p: 2 }}>
        <Typography variant="h5" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste des dernieres demande
        </Typography>
      </Box>

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell sx={{ textAlign: "center" }}>Qui ?</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Pour</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Combien</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Quand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              listReboursement?.map(remboursement => {
                return (
                  <TableRow key={Math.random()} >
                    <TableCell sx={{ textAlign: "center" }}>{remboursement.user.name}</TableCell>
                    <TableCell sx={{ textAlign: "center" }} > {remboursement.reason}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{formatAmount(remboursement.amount)}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{new Date(remboursement.createdAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default Remboursement;
