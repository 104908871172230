import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import Header from '../components/header';
import { intervalTime } from '../utils';
import { Box, Typography, Paper, TextField, Switch } from '@mui/material';

function Outils() {
  let navigate = useNavigate()

  const [calculPropre, setCalculPropre] = useState({
    pourcentage: 40,
    propre: 0,
    sale: 0,
    switch: false
  })

  useEffect(() => {
    updateCalcul()
  }, [calculPropre])


  const updateCalcul = async () => {
    if (!calculPropre.switch) {
      setCalculPropre(prev => ({ ...prev, propre: calculPropre.sale * (1 - (calculPropre.pourcentage / 100)) }))
    } else {
      setCalculPropre(prev => ({ ...prev, sale: calculPropre.propre / (1 - (calculPropre.pourcentage / 100)) }))
    }
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Convertisseur Sale/Propre</Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column-reverse', gap: 3 }}>
          <Box>
            <TextField label="%" value={calculPropre.pourcentage} sx={{ width: "5rem" }} onChange={(e) => {
              setCalculPropre(prev => ({ ...prev, pourcentage: parseInt(e.target.value) }))
            }}></TextField>
          </Box>
          <Box>
            <TextField label="Sale" disabled={calculPropre.switch} value={calculPropre.sale} onChange={(e) => setCalculPropre(prev => ({ ...prev, sale: parseInt(e.target.value) }))}></TextField>
            <Switch value={calculPropre.switch} onChange={(e) => { setCalculPropre(prev => ({ ...prev, switch: e.target.checked })) }}></Switch>
            <TextField label="Propre" disabled={!calculPropre.switch} value={calculPropre.propre} onChange={(e) => setCalculPropre(prev => ({ ...prev, propre: parseInt(e.target.value) }))}></TextField>
          </Box>
        </Box>
      </Paper>
    </>

  );
}

export default Outils;
