import { CN } from "./types";


export enum role {
    desactivé = "desactivé",
    user = "Membre",
    admin = "Admin"
  }

const envVar = parseInt(process.env.REACT_APP_INTERVAL_TIME || "")
export const intervalTime = Number.isInteger(envVar) ? envVar : 20000

const dollarCurrency = new Intl.NumberFormat('fr-FR', {
    maximumFractionDigits: 0,
});

export function formatCurrency(amount: number) {
    let currency = dollarCurrency.format(amount)

    return "$" + currency
}

export function formatAmount(amount: number) {
    return dollarCurrency.format(amount)
}


export function sumCn(CnList: CN[]) {
    let sum = 0
    for (let i = 0; i < CnList.length; i++) {
        sum += CnList[i].amount
    }
    return sum

}

export function getCurrencyColor(amount: number) {
    return amount >= 0 ? "success.main" : "error.main"
}

export function formatWeight(poids: number){
    return `${poids/1000}Kg`
}

export function moveToID(id: string){
    setTimeout(() => {
      const correctDiv = document.getElementById(id);
      if (correctDiv) {
        correctDiv.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }

export function removeDay(startingDate: Date, numberDays: number) {
    return new Date(new Date().setDate(startingDate.getDate() - numberDays));
}