import React, { useEffect } from 'react';
import './css/discord.css';
// import { ReactComponent as DiscordLogo} from './asset/discord-logo.svg'
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import { Box, Button, Paper } from '@mui/material';
import LoginOutlined from '@mui/icons-material/LoginOutlined';
import logo from './asset/LogoManoRond.png'

function Discord() {
  let navigate = useNavigate()

  useEffect(() => {
    checkIsConnected()
    document.body.classList.add('discord')
  })

  const checkIsConnected = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/profile', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })
      if (response.status === 400) return
      const data: User | false = await response.json();

      if (data === false) return
      return navigate("/")


    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  return (
    <div className="App">
      <header className="App-body" style={{padding: 0}}>
        {/* <a className="discord" href={process.env.REACT_APP_BACKEND_URL +"/auth/discord"}>
          <DiscordLogo />
          <span className="tex-sm">Sign in with Discord</span>
        </a> */}
        <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
          <Paper elevation={16} sx={{ p: 4, pt: 2 }}>
            <img src={logo} alt="logo" style={{ width: '100%', height: 'auto' }} />
            <br />
            <Button color='primary' variant='contained' size='large' startIcon={<LoginOutlined />} component='a' href={process.env.REACT_APP_BACKEND_URL + "/auth/discord"} >SE CONNECTER</Button>
          </Paper>
        </Box>
      </header>
    </div>
  );
}

export default Discord;
