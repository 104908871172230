import React, { useEffect, useState } from 'react';
import './css/home.css';
import Header from '../components/header';
import HistoriqueClient from '../components/historiqueClient';
import HistoriqueGlobal from '../components/historiqueGlobal';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

function Historique() {

  const queryParameters = new URLSearchParams(window.location.search)
  const clientID = queryParameters.get("id")

  const [query, setQuery] = useSearchParams()

  return (
    <>
      {clientID ? <HistoriqueClient /> : <HistoriqueGlobal />}
    </>
  );
}

export default Historique;
