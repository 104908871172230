import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { item, price, User } from '../types';
import Header from '../components/header';
import { intervalTime, role } from '../utils';
import { Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, MenuItem, Button, Snackbar, Alert, Checkbox, FormControlLabel } from '@mui/material';

function GestionPrice({user}: {user: User | undefined}) {
  let navigate = useNavigate()
  if (!user || user.role !== role.admin) navigate('/')


  const [listPrice, setListPrice] = useState<price[]>([])


  const [itemList, setItemList] = useState<item[]>()

  const [newPrice, setNewPrice] = useState<{ itemID: number, price: number | undefined, pourcentage: number | undefined, alwaysShow: boolean, type: string }>({
    alwaysShow: false,
    itemID: 0,
    type: "rachat",
    price: 0,
    pourcentage: 0
  })


  const [open, setOpen] = useState(false)
  const [notifText, setNotifText] = useState("")
  const handleClose = () => setOpen(false)

  useEffect(() => {
    getPriceList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const getPriceList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/price/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: price[] = await response.json();

      setListPrice(data)
      getItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getItemList = async (list: price[]) => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();

      let firstItem = newPrice.itemID
      setItemList(data.filter(item => {
        for (let i = 0; i < list.length; i++) {
          if (list[i].itemID === item.id) return
        }
        if (firstItem === 0) {
          setNewPrice(prev => ({ ...prev, itemID: item.id }))
          firstItem = item.id
        }
        return item
      }))
    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const changePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setListPrice(tl => {
      const list = tl?.map((price) => {
        if (price.id === id) {
          if (price.type === "blanchi") price.pourcentage = parseInt(e.target.value)
          else price.price = parseInt(e.target.value)
          console.log(price)
        }
        return price
      })
      return list
    })
  }

  const changeType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setListPrice(tl => {
      const list = tl?.map((price) => {
        if (price.id === id) {
          price.type = e.target.value
        }
        return price
      })
      return list
    })
  }

  const changeShow = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setListPrice(tl => {
      const list = tl?.map((price) => {
        if (price.id === id) {
          price.alwaysShow = e.target.checked
        }
        return price
      })
      return list
    })
  }

  const changeNewPrice = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (newPrice.type === "blanchi") setNewPrice(prev => ({ ...prev, pourcentage: parseInt(e.target.value) }))
    else setNewPrice(prev => ({ ...prev, price: parseInt(e.target.value) }))
  }


  const updatePrice = async (id: number) => {
    let prix

    if (!listPrice) return
    for (let i = 0; i < listPrice.length; i++) {
      if (listPrice[i].id === id) {
        prix = listPrice[i]
        break
      }
    }

    console.log(prix)
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/price/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ prix: prix, type: "update" })
    })


    setNotifText("Le prix a bien été modifié")
    getPriceList()
    setOpen(true)
  }


  const addPrice = async () => {
    if (newPrice.itemID === 0) return

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/price/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ prix: newPrice, type: "add" })
    })


    setNotifText("Le prix a bien été ajouté")
    getPriceList()
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste Prix
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Prix/Pourcentage</TableCell>
              <TableCell>Type</TableCell>
              <TableCell></TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listPrice?.map(price => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell sx={{ color: price.item.color }}>
                    {price.item.name}
                  </TableCell>
                  <TableCell>
                    <TextField size='small' id='mouvement' label={price.type === "blanchi" ? "%" : "$"} defaultValue={price.type === "blanchi" ? price.pourcentage : price.price} variant='outlined' onBlur={(e) => changePrice(e, price.id)} />
                  </TableCell>
                  <TableCell>
                    <TextField id='cn' select label="Type" variant='outlined' value={price.type} onChange={(e) => { changeType(e, price.id) }}>
                      <MenuItem key={"blanchi"} value={"blanchi"}>
                        {"blanchi"}
                      </MenuItem>
                      <MenuItem key={"rachat"} value={"rachat"}>
                        {"rachat"}
                      </MenuItem>
                      <MenuItem key={"vente"} value={"vente"}>
                        {"vente"}
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={price.alwaysShow} onChange={(e) => { changeShow(e, price.id) }}
                        />
                      }
                      label="Toujours Montrer"
                      labelPlacement='end'
                    />
                  </TableCell>
                  <TableCell>
                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={() => updatePrice(price.id)}>Modifier</Button>

                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow key={Math.random()}>
              <TableCell >
                <TextField sx={{ margin: "1rem" }} id='item' select label="Items" variant='outlined' value={newPrice.itemID}
                  onChange={(e) => { setNewPrice(prev => ({ ...prev, itemID: parseInt(e.target.value) })) }}
                  InputProps={{ sx: { color: itemList?.filter(item => item.id === newPrice.itemID).map(o => o.color) } }}
                  SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
                >
                  {itemList?.map((option) => (
                    <MenuItem key={option.name} value={option.id} sx={{ color: option.color }}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell>
                <TextField size='small' id='mouvement' label={newPrice.type === "blanchi" ? "%" : "$"} defaultValue={newPrice.price} variant='outlined' onBlur={(e) => changeNewPrice(e)} />
              </TableCell>
              <TableCell>
                <TextField id='cn' select label="Type" variant='outlined' value={newPrice.type} onChange={(e) => { setNewPrice(prev => ({ ...prev, type: e.target.value })) }}>
                  <MenuItem key={"blanchi"} value={"blanchi"}>
                    {"blanchi"}
                  </MenuItem>
                  <MenuItem key={"rachat"} value={"rachat"}>
                    {"rachat"}
                  </MenuItem>
                  <MenuItem key={"vente"} value={"vente"}>
                    {"vente"}
                  </MenuItem>
                </TextField>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newPrice.alwaysShow} onChange={(e) => { setNewPrice(prev => ({ ...prev, alwaysShow: e.target.checked })) }}
                    />
                  }
                  label="Toujours Montrer"
                  labelPlacement='end'
                />
              </TableCell>
              <TableCell>
                <Button color='success' variant='contained' sx={{ textAlign: "center" }} onClick={() => addPrice()}>Ajouter</Button>

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>

    </>
  );
}

export default GestionPrice;
