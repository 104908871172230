import React, { useEffect, useState } from 'react';
import './css/home.css';
import { CN, histoCN } from '../types';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, MenuItem, Alert, Switch, FormControlLabel, Paper, Snackbar, Typography } from '@mui/material';
import { formatCurrency, getCurrencyColor, intervalTime, sumCn } from '../utils';
import { paperTable } from '../theme/styles';
import Title from '../components/Title';

function CaisseNoir() {
  let navigate = useNavigate()

  // const [user, setUser] = useState<User>();

  const [open, setOpen] = useState(false)

  const [cnList, setCnList] = useState<CN[]>([])

  const [histoCN, setHistoCN] = useState<histoCN[]>([])

  const [mouvement, setMouvement] = useState<{ amount: number | undefined, commentaire: string, selectedCN: number, amountValue: string, transferCN: number, IsTransfert: boolean }>({
    amountValue: '',
    amount: undefined,
    commentaire: "",
    selectedCN: 1,
    transferCN: 1,
    IsTransfert: false
  })

  const handleClose = () => setOpen(false)

  useEffect(() => {
    // getUser()
    getCNList()
    getHistoCN()

    const interval = setInterval(() => {
      getCNList()
      getHistoCN()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])



  const getCNList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: CN[] = await response.json();

      setCnList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getHistoCN = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/histo', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: histoCN[] = await response.json();

      setHistoCN(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const handleCNClick = async () => {
    if (mouvement.amount === 0) return
    if (mouvement.commentaire === "") return
    await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(mouvement)
    })

    setMouvement(prev => ({
      ...prev,
      amount: 0,
      amountValue: "",
      commentaire: "",
    }))

    getCNList()
    getHistoCN()

    setOpen(true)
  }

  return (
    <>
      <Title text='Caisse Noire'/>

      <Paper elevation={16} sx={{ ...paperTable }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Compte</TableCell>
              <TableCell>Restant</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cnList?.map(cn => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell>{cn.name}</TableCell>
                  <TableCell sx={{ color: getCurrencyColor(cn.amount), fontWeight: "normal" }}>{formatCurrency(cn.amount)}</TableCell>

                </TableRow>
              )
            })}
            <TableRow key={Math.random()} >
              <TableCell sx={{ fontWeight: "bold", fontSize: "15px" }}>Caisse Noire</TableCell>
              <TableCell sx={{ color: getCurrencyColor(sumCn(cnList)), fontWeight: "bold", fontSize: "15px" }}>{formatCurrency(sumCn(cnList))}</TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>

     <Title text='Faire un mouvement' variant='h5'/>
      <Paper elevation={16} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField id='mouvement' label="Mouvement" variant='outlined' value={mouvement.amountValue} error={Number.isNaN(mouvement.amount)} onChange={(e) => {
            setMouvement(prev => ({ ...prev, amount: parseInt(e.target.value), amountValue: e.target.value }))
          }} />
          <TextField id='commentaire' label="Commentaire" variant='outlined' value={mouvement.commentaire} onChange={(e) => { setMouvement(prev => ({ ...prev, commentaire: e.target.value })) }} />
          <TextField id='cn' select label="Compte" variant='outlined' value={mouvement.selectedCN} onChange={(e) => { setMouvement(prev => ({ ...prev, selectedCN: parseInt(e.target.value) })) }}>
            {cnList.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <FormControlLabel control={<Switch checked={mouvement.IsTransfert} onChange={() => setMouvement(prev => ({ ...prev, IsTransfert: !mouvement.IsTransfert }))} />} label="Transfert" />

        {mouvement.IsTransfert && (
          <>
            <br />
            <TextField id='cn' select label="Compte" variant='outlined' value={mouvement.transferCN} onChange={(e) => { setMouvement(prev => ({ ...prev, transferCN: parseInt(e.target.value) })) }}>
              {cnList.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
        <br />
        <Button variant='outlined' color='success' onClick={handleCNClick}>
          Valider
        </Button>
      </Paper>

      <Title text='Historique mouvement' variant='h5'/>
      <Paper elevation={16} sx={{ ...paperTable }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Compte</TableCell>
              <TableCell>Somme</TableCell>
              <TableCell>Raison</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {histoCN?.map(cn => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell>{new Date(cn.createdAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })}</TableCell>
                  <TableCell>{cn.cn.name}</TableCell>
                  <TableCell sx={{ color: getCurrencyColor(cn.amount), fontWeight: "bold" }}>{formatCurrency(cn.amount)}</TableCell>
                  <TableCell>{cn.commentaire}</TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default CaisseNoir;
