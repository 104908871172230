import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import Header from '../components/header';
import { intervalTime, role } from '../utils';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, MenuItem, Alert, Snackbar, Box, Typography } from '@mui/material';

function GestionUser({user}: {user: User | undefined}) {
  let navigate = useNavigate()
  if (!user || user.role !== role.admin) navigate('/')


  const [listUser, setListUser] = useState<User[]>([])

  const [newUser, setNewUser] = useState<User>({
    etat: "stop",
    discordId: "",
    name: "",
    id: 0,
    role: "Membre",
    connected: new Date(Date.now()),
    disconnected: new Date(Date.now())
  })


  const [open, setOpen] = useState(false)
  const [notifText, setNotifText] = useState("")
  const handleClose = () => setOpen(false)

  useEffect(() => {
    getUserList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const getUserList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: User[] = await response.json();

      setListUser(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const changeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setListUser(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.name = e.target.value
        }
        return item
      })
      return list
    })
  }

  const changeID = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setListUser(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.discordId = e.target.value
        }
        return item
      })
      return list
    })
  }

  const changeRole = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setListUser(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.role = e.target.value
        }
        return item
      })
      return list
    })
  }


  const changeUser = async (id: number) => {
    let user

    if (!listUser) return
    for (let i = 0; i < listUser.length; i++) {
      if (listUser[i].id === id) {
        user = listUser[i]
        break
      }
    }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/user/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ user: user, type: "update" })
    })


    setNotifText("L'utilisateur a bien été modifié")
    getUserList()
    setOpen(true)
  }


  const addUser = async () => {
    console.log(newUser)
    if (newUser.discordId === "") return
    if (newUser.name === "") return

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/user/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ user: newUser, type: "add" })
    })


    setNotifText("L'utilisateur a bien été ajouté")
    getUserList()
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste Utilisateur
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Membre</TableCell>
              <TableCell>ID Discord</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser?.map(user => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell >
                    <TextField size='small' id='mouvement' label="Nom" defaultValue={user.name} variant='outlined' onBlur={(e) => changeName(e, user.id)} />
                  </TableCell>
                  <TableCell>
                    <TextField size='small' id='mouvement' label="ID" defaultValue={user.discordId} variant='outlined' onBlur={(e) => changeID(e, user.id)} />
                  </TableCell>
                  <TableCell>
                    <TextField id='cn' select label="Role" variant='outlined' value={user.role} onChange={(e) => { changeRole(e, user.id) }}>
                      <MenuItem key={role.desactivé} value={role.desactivé}>
                        {role.desactivé}
                      </MenuItem>
                      <MenuItem key={role.user} value={role.user}>
                        {role.user}
                      </MenuItem>
                      <MenuItem key={role.admin} value={role.admin}>
                        {role.admin}
                      </MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={() => changeUser(user.id)}>Modifier</Button>

                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow key={Math.random()}>
              <TableCell >
                <TextField size='small' id='mouvement' label="Nom" defaultValue={newUser.name} variant='outlined' onBlur={(e) => setNewUser(prev => ({ ...prev, name: e.target.value }))} />
              </TableCell>
              <TableCell>
                <TextField size='small' id='mouvement' label="ID" defaultValue={newUser.discordId} variant='outlined' onBlur={(e) => setNewUser(prev => ({ ...prev, discordId: e.target.value }))} />
              </TableCell>
              <TableCell>
                <TextField id='cn' select label="Role" variant='outlined' value={newUser.role} onChange={(e) => { setNewUser(prev => ({ ...prev, role: e.target.value })) }}>
                  <MenuItem key={role.desactivé} value={role.desactivé}>
                    {role.desactivé}
                  </MenuItem>
                  <MenuItem key={role.user} value={role.user}>
                    {role.user}
                  </MenuItem>
                  <MenuItem key={role.admin} value={role.admin}>
                    {role.admin}
                  </MenuItem>
                </TextField>
              </TableCell>
              <TableCell>
                <Button color='success' variant='contained' sx={{ textAlign: "center" }} onClick={() => addUser()}>Ajouter</Button>

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>

    </>
  );
}

export default GestionUser;
