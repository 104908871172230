import { Box, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { titles } from "../theme/styles";

function Title({ text, variant = "h4" }: { text: string; variant?: Variant }) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant={variant}
        color="text.primary"
        sx={titles}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default Title;
