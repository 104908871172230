import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useSearchParams } from 'react-router-dom';
import { annuaire } from '../types';
import Header from '../components/header';
import { intervalTime, moveToID } from '../utils';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Box, Typography, MenuItem, TextField, Popover, Tooltip, IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

function Annuaire() {

  const [annuaire, setAnnuaire] = useState<annuaire[]>()

  const queryParameters = new URLSearchParams(window.location.search)
  const annuaireID = queryParameters.get("id")


  const [newNumber, setNewNumber] = useState<{ type: number, number: string, name: string }>({
    type: 1,
    number: "555-",
    name: ""
  })

  const [updatedNumber, setUpdateNumber] = useState<{ id: number, type: number, number: string, name: string }>({
    id: 0,
    type: 1,
    number: "555-",
    name: ""
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , id: number, number: string, type: number, name: string) => {
    setUpdateNumber(prev => ({ ...prev, id: id, number: number, type: type, name: name }))
    setAnchorEl(event.currentTarget)
  }
  const isPopoverOpen = Boolean(anchorEl)


  const [query, setQuery] = useSearchParams()
  // if (annuaireID) moveToID(annuaireID)

  useEffect(() => {
    getAnnuaireList().then(() => {
      if (annuaireID) moveToID(annuaireID)
    })
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getAnnuaireList = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/annuaire/getAll', {
      mode: 'cors',
      method: "GET",
      credentials: "include"
    })

    const data: annuaire[] = await response.json()

    setAnnuaire(data)

  }

  const addNumber = async () => {
    if (newNumber.number === "555-") return
    if (newNumber.name === "") return
    console.log(newNumber)
    await fetch(process.env.REACT_APP_BACKEND_URL + '/annuaire/number/add', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newNumber)
    })

    setNewNumber(prev => ({
      ...prev,
      name: "",
      number: "555-"
    }))
    getAnnuaireList()

  }

  const updateNumber = async () => {
    if (updatedNumber.number === "555-") return
    if (updatedNumber.name === "") return
    console.log(updatedNumber)
    await fetch(process.env.REACT_APP_BACKEND_URL + '/annuaire/number/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(updatedNumber)
    })

    setUpdateNumber(prev => ({
      ...prev,
      name: "",
      number: "555-"
    }))
    getAnnuaireList()
    handleClose()

  }

  return (
    <>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Ajouter un numero
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <TextField sx={{ margin: "1rem" }} id='stockage' select label="Type" variant='outlined' value={newNumber.type} onChange={(e) => { setNewNumber(prev => ({ ...prev, type: parseInt(e.target.value) })) }}>

          {
            annuaire?.map(annuaire => {
              return (
                <MenuItem key={annuaire.id} value={annuaire.id}>
                  {annuaire.name}
                </MenuItem>

              )
            })
          }

        </TextField>
        <TextField sx={{ margin: "1rem" }} id='item' label="Numero" variant='outlined' error={!newNumber.number.startsWith("555-") || newNumber.number.length > 8} value={newNumber.number} onChange={(e) => { setNewNumber(prev => ({ ...prev, number: e.target.value })) }}>

        </TextField>
        <TextField sx={{ margin: "1rem" }} id='mouvement' label="Nom Prenom" variant='outlined' error={newNumber.name === ""} value={newNumber.name} onChange={(e) => { setNewNumber(prev => ({ ...prev, name: e.target.value })) }} />

        <Button onClick={addNumber}>Ajouter</Button>
      </Paper>

      {
        annuaire?.map(annuaire => {
          if (annuaire.name === "Desactivé") return ""
          return (

            <>
              <Box sx={{ p: 2 }} id={annuaire.id.toString()} >
                <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{annuaire.name}</Typography>
              </Box>
              <Paper elevation={16} sx={{ p: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>Numero</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Nom</TableCell>
                      <TableCell sx={{ textAlign: "center" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {annuaire.AnnuaireNumber?.map(client => {
                      return (
                        <TableRow key={Math.random()}>
                          <TableCell sx={{ textAlign: "center" }}>{client.number}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{client.name}</TableCell>
                          <TableCell >
                            <Tooltip title="Modifier">
                              <IconButton color="primary" size='small' sx={{ minWidth: 0 }} onClick={(e) => handleOpen(e, client.id, client.number, client.annuaireID, client.name)} ><EditOutlined /></IconButton>
                            </Tooltip>
                          </TableCell>

                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </>
          )
        })
      }

      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Modifier le numero
          </Typography>
          <TextField sx={{ margin: "1rem" }} id='stockage' select label="Type" variant='outlined' value={updatedNumber.type} onChange={(e) => { setUpdateNumber(prev => ({ ...prev, type: parseInt(e.target.value) })) }}>
            {
              annuaire?.map(annuaire => {
                return (
                  <MenuItem key={annuaire.id} value={annuaire.id}>
                    {annuaire.name}
                  </MenuItem>

                )
              })
            }

          </TextField>
          <TextField sx={{ margin: "1rem" }} id='item' label="Numero" variant='outlined' error={!updatedNumber.number.startsWith("555-") || updatedNumber.number.length > 8} value={updatedNumber.number} onChange={(e) => { setUpdateNumber(prev => ({ ...prev, number: e.target.value })) }}>

          </TextField>
          <TextField sx={{ margin: "1rem" }} id='mouvement' label="Nom Prenom" variant='outlined' error={updatedNumber.name === ""} value={updatedNumber.name} onChange={(e) => { setUpdateNumber(prev => ({ ...prev, name: e.target.value })) }} />

          <Button onClick={updateNumber}>Valider</Button>
        </Paper>
      </Popover>
    </>
  );
}

export default Annuaire;
