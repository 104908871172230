import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';

// import Root from './routes/route';

import { ThemeProvider } from '@emotion/react';
import { CssBaseline, responsiveFontSizes, createTheme } from '@mui/material';

import { frFR as frData } from '@mui/x-data-grid/locales';
import { frFR } from '@mui/material/locale';

import fr from 'rsuite/locales/fr_FR';
import { CustomProvider } from 'rsuite';
import AppRoutes from './Routes/AppRoutes';



const Theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#61afef'
    },
    secondary: {
      main: '#c678dd'
    },
    success: {
      main: '#98c379'
    },
    warning: {
      main: '#d19a66'
    },
    error: {
      main: '#e06c75'
    },
    info: {
      main: '#f0b6ba'
    },
    text: {
      primary: '#abb2bf',
      secondary: 'rgba(171,178,191,0.9)',
      disabled: 'rgba(171,178,191,0.5)'
    },
    background: {
      default: '#24272e',
      paper: '#282c34'
    }
  },
  typography: {
    fontFamily: '"Noto Sans", Helvetica, Arial, sans-serif',
    fontSize: 16,
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#282c34'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'hidden'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'center'
        }
      }
    }
  }

},
  frData,
  frFR
)

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(Theme)}>
      <CssBaseline enableColorScheme />
      <CustomProvider locale={fr} theme="dark">
        <BrowserRouter>
          <AppRoutes />
          </BrowserRouter>
      </CustomProvider>
    </ThemeProvider>
  </React.StrictMode>
);
