export const titles = {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'text.primary',
    textShadow: '0px 0px 5px #000'
}

export const paperTable = {
    px: 4,
    py: 2
}